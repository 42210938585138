@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .item {
        align-items: flex-start;
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.06);
        border-radius: 0.25rem;
        border-left: 0.25rem solid red;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 1rem;
        position: relative;
        cursor: pointer;

        &:hover .setPrimary {
          display: block;
        }
      }

      .disabled {
        pointer-events: none;
      }

      .control {
        flex-shrink: 0;
        width: 1rem;
      }

      .details {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        overflow: hidden;

        .name {
          display: flex;
          flex-direction: row;
          font-weight: 500;
          gap: 0.5rem;
          overflow: hidden;

          & > label {
            cursor: pointer;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &.disabled {
          .name {
            & > label {
              cursor: initial;
            }
          }
        }
      }

      .info {
        align-items: center;
        display: flex;
        flex-shrink: 0;
        font-weight: normal;
      }

      .infoIcon {
        color: colors.$mmc-accent-gray;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-width: 40rem;

        .title {
          font-weight: 500;
        }

        .body {
          max-height: 10rem;
          overflow: auto;
        }

        .grid {
          gap: 0.25rem;
        }
      }

      .infoText {
        display: flex;
        align-items: center;
        gap: 0.375rem;
        color: colors.$mmc-soft-slate;
      }

      .badge {
        color: colors.$mmc-soft-slate;
      }

      .tag {
        display: flex;
        gap: 0.25rem;
        align-items: center;
        margin-right: 0;
      }

      .star {
        color: colors.$mmc-yellow-100;
      }

      .setPrimary {
        display: none;
      }

      .buttonVisible {
        display: block;
      }

      .avatar {
        flex-shrink: 0;
      }

      .metadata {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
